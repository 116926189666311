import {Issue} from "./interfaces/Issue";

/**
 * @summary タスク情報を提供します.
 */
export class IssueModel implements Issue {
    threadCount = 0;
    sheetId = 0;
    issueId = 0;
    systemId = 1;
    statusId = 0;
    personId = 1;
    requestDetails = "";
    workingDetails = "";
    creationDate: string;

    /**
     * @summary コンストラクタ
     * @param task タスクデータ
     * @param gantChart ガントチャートデータ
     */
    public constructor(issue?: Partial<Issue>) {
        const date = new Date();
        this.creationDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        if (issue) {
            Object.assign(this, issue);
        }
    }
}
