var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1024px"},model:{value:(_vm.isOpend),callback:function ($$v) {_vm.isOpend=$$v},expression:"isOpend"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("新規登録")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[[_c('div',[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.masterInfoStore.systemsMaster.values,"label":"システム"},model:{value:(_vm.issue.systemId),callback:function ($$v) {_vm.$set(_vm.issue, "systemId", $$v)},expression:"issue.systemId"}})],1)]],2),_c('v-col',[[_c('div',[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":Object.entries(_vm.masterInfoStore.issueStatusesMaster).map(function (ref) {
	var key = ref[0];
	var value = ref[1];

	return (value);
}),"label":"ステータス"},model:{value:(_vm.issue.statusId),callback:function ($$v) {_vm.$set(_vm.issue, "statusId", $$v)},expression:"issue.statusId"}})],1)]],2),_c('v-col',[[_c('div',[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":Object.entries(_vm.masterInfoStore.personsMaster).map(function (ref) {
	var key = ref[0];
	var value = ref[1];

	return (value);
}),"label":"担当者"},model:{value:(_vm.issue.personId),callback:function ($$v) {_vm.$set(_vm.issue, "personId", $$v)},expression:"issue.personId"}})],1)]],2)],1),_c('v-row',[_c('v-col',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"作成日","readonly":""},model:{value:(_vm.issue.creationDate),callback:function ($$v) {_vm.$set(_vm.issue, "creationDate", $$v)},expression:"issue.creationDate"}},on))]}}])},[[_c('v-date-picker',{model:{value:(_vm.issue.creationDate),callback:function ($$v) {_vm.$set(_vm.issue, "creationDate", $$v)},expression:"issue.creationDate"}})]],2)],1)],1),_c('v-row',[_c('v-col',[[_c('div',[_c('v-textarea',{attrs:{"counter":"","label":"依頼内容"},model:{value:(_vm.issue.requestDetails),callback:function ($$v) {_vm.$set(_vm.issue, "requestDetails", $$v)},expression:"issue.requestDetails"}})],1)]],2),_c('v-col',[[_c('div',[_c('v-textarea',{attrs:{"counter":"","label":"対応内容"},model:{value:(_vm.issue.workingDetails),callback:function ($$v) {_vm.$set(_vm.issue, "workingDetails", $$v)},expression:"issue.workingDetails"}})],1)]],2)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.ok}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }