



















































































































import {Vue, Component} from "vue-property-decorator";
import {IssueModel} from "../../models/IssueModel";
import {TaskStore, TaskStoreModule} from "../../stores/TaskStore";
import {MasterInfoStore, MasterInfoStoreModule} from "../../stores/MasterInfoStore";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";

@Component
export default class AddIssueDialog extends Vue {
    private resolve?: (value: IssueModel | undefined | PromiseLike<IssueModel | undefined>) => void = undefined;
    private issue = new IssueModel({});
    private isOpend = false;

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary エラー報告を追加するためのダイアログを開きます.
     * @param issue エラー報告
     * @returns 追加する内容
     */
    public showAsync(issue: IssueModel): Promise<IssueModel | undefined> {
        this.isOpend = true;
        this.issue = issue;
        return new Promise<IssueModel | undefined>(resolve => {
            this.resolve = resolve;
        });
    }

    /**
     * @summary キャンセルしてダイアログを閉じます.
     */
    private cancel(): void {
        this.isOpend = false;
        this.resolve!(undefined);
    }

    /**
     * @summary ダイアログを閉じて追加するエラー報告を取得します.
     */
    private ok() {
        this.isOpend = false;
        if (this.sheetStore.currentSheetId) {
            this.issue.sheetId = this.sheetStore.currentSheetId;
        }
        this.resolve!(this.issue);
    }
}
