











































































































































import {Vue, Component, Watch} from "vue-property-decorator";
import {IssueStoreModule, IssueThreadStore} from "@/stores/IssueThreadStore";
import {IssueThread} from "@/models/interfaces/IssueThread";
import {Issue} from "@/models/interfaces/Issue";
import {IssueModel} from "@/models/IssueModel";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import EditThreadDialog from "../Organisms/EditThreadDialog.vue";
import NotifyDialog from "@/components/Atoms/NotifyDialog.vue";
import ConfirmDialog from "@/components/Atoms/ConfirmDialog.vue";

/**
 * @summary エラー報告に紐づくスレッドを表示するためのコンポーネントを提供します.
 */
@Component({
    components: {
        EditThreadDialog,
        NotifyDialog,
        ConfirmDialog
    }
})
export default class IssueThreadView extends Vue {
    // #region private fields
    private isOpend = false;
    private resolve?: (value?: void | PromiseLike<void> | undefined) => void = undefined;
    private issue?: Issue = new IssueModel();
    private isLoading = false;
    private message = "";
    // #endregion

    // #region private getters
    /**
     * @summary エラー報告スレッドストア
     */
    private get issueThreadStore(): IssueThreadStore {
        return IssueStoreModule;
    }

    /**
     * @summary 認証情報ストア
     */
    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    /**
        * @summary マスタ情報ストア
        */
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }
    // #endregion

    // #region public methods
    /**
     * @summary エラー報告スレッドビューを表示します.
     */
    public showAsync(issue: Issue): Promise<void> {
        this.isOpend = true;
        this.issue = issue;
        this.initData();
        return new Promise<void>(resolve => this.resolve);
    }
    // #endregion

    // #region private methods
    /**
     * @summary サイドバーが変化したとき.
     * @param isOpend 開いたか閉じたか
     */
    @Watch("isOpend")
    private onIsOpendChanged(isOpend: boolean): void {
        if (!isOpend && this.resolve) {
            this.resolve();
        }
    }

    /**
     * @summary 閉じる
     */
    public close() {
        this.isOpend = false;
    }

    /**
     * @summary 最下部までスクロールします.
     */
    private scrollBottom(): void {
        this.$nextTick(() => {
            const panel = this.$refs.scrollPanel as HTMLDialogElement;
            if (panel) {
                panel.scrollTop = panel.scrollHeight;
            }
        });
    }

    /**
     * @summary データを初期化します.
     */
    private async initData(): Promise<void> {
        this.isLoading = true;
        if (this.issue) {
            await this.issueThreadStore.fetchIssueThreads(this.issue.issueId);
        }
        this.scrollBottom();
        this.isLoading = false;
    }

    /**
     * @summary エラー報告スレッドを追加します.
     */
    private async addIssueThread(): Promise<void> {
        if (!this.issue) {
            return;
        }
        const isSuccess = await this.issueThreadStore.addIssueThread({
            personId: this.authStore.memberId,
            text: this.message,
            issueThreadId: 0,
            issueId: this.issue.issueId,
            createdAt: new Date().toLocaleString(),
            updatedAt: new Date().toLocaleString()
        });
        this.issue.threadCount++;
        this.message = "";
        this.scrollBottom();
    }
    // #endregion

    private openEditThreadDialog(threadId: number, text: string) {
        const dialog = this.$refs.editThreadDialog as EditThreadDialog;
        if (!dialog) {
            return;
        }

        dialog.show(threadId, text);
    }

    private async editThreadText(threadId: number, text: string) {
        const notify = this.$refs.NotifyDialog as NotifyDialog;

        const data: IssueThread = {issueThreadId: threadId, text, personId: 0, issueId: 0, createdAt: "", updatedAt: ""};
        const result = await this.issueThreadStore.editTaskThreadText(data);
        if (result) {
            if (notify) {
                await notify.showAsync("Info", "スレッドを編集しました", "閉じる");
            }
            else {
                alert("スレッドを編集しました");
            }

            const dialog = this.$refs.editThreadDialog as EditThreadDialog;
            if (dialog) {
                dialog.close();
            }

            this.isLoading = true;
            if (this.issue) {
                await this.issueThreadStore.fetchIssueThreads(this.issue.issueId);
            }
            this.isLoading = false;
        }
        else {
            if (notify) {
                await notify.showAsync("Info", "編集に失敗しました", "閉じる");
            }
            else {
                alert("編集に失敗しました");
            }
        }
    }

    private async openDeleteDialog(threadId: number) {
        const dialog = this.$refs.ConfirmDialog as ConfirmDialog;
        if (!dialog) {
            return;
        }

        const confirmResult = await dialog.showAsync(
            "確認",
            "本当に削除しますか？\nこの操作は取り消せません",
            "はい、削除します",
            "いいえ、キャンセルします"
        );

        if (!confirmResult) {
            return;
        }

        const notify = this.$refs.NotifyDialog as NotifyDialog;

        const result = await this.issueThreadStore.delete(threadId);
        if (result) {
            if (notify) {
                await notify.showAsync("Info", "削除しました", "閉じる");
            }
            else {
                alert("削除しました");
            }

            this.isLoading = true;
            if (this.issue) {
                await this.issueThreadStore.fetchIssueThreads(this.issue.issueId);
                this.issue.threadCount--;
            }
            this.isLoading = false;
        }
    }
}
