




































































import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {DefaultTaskSearchParam} from "@/Config";
import {IdAndName} from "../../models/interfaces/IdAndName";
import {IssueSearchParamModel} from "../../models/IssueSearchParamModel";
import {IssueSearchParam} from "../../models/interfaces/IssueSearchParam";
import moment from "moment";

/**
 * @summary タスクを検索する為のフォームを提供します
 */
@Component({
    components: {
    }
})
export default class IssueSearchForm extends Vue {
    @Prop({default: new IssueSearchParamModel()}) readonly params!: IssueSearchParam;
    @Prop({default: []}) readonly items!: IdAndName[];

    private dates: string[] = [];
    private datesText = "";

    private isSelectAll = false;
    private isSelectSome = false;

    @Watch("dates")
    private datesToText() {
        this.params.createDateArr = this.dates;

        if (this.params && this.params.createDateArr && this.params.createDateArr.length > 0) {
            const date1 = this.params.createDateArr[0] ? moment(this.params.createDateArr[0]) : undefined;
            const date2 = this.params.createDateArr[1] ? moment(this.params.createDateArr[1]) : undefined;

            let from = "";
            let end = "";
            if (date1 && date2) {
                if (date2.isAfter(date1)) {
                    from = date1.format("YYYY年MM月DD日");
                    end = date2.format("YYYY年MM月DD日");
                }
                else {
                    end = date1.format("YYYY年MM月DD日");
                    from = date2.format("YYYY年MM月DD日");
                }
            }
            else if (date1) {
                from = date1.format("YYYY年MM月DD日");
            }
            else if (date2) {
                from = date2.format("YYYY年MM月DD日");
            }

            if (from) {
                this.datesText = from + "～" + end;
            }

            return this.datesText;
        }

        return "";
    }

    private icon(): string {
        if (this.isSelectAll) return "mdi-close-box";
        if (this.isSelectSome) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
    }

    private mounted() {
        if (this.params.createDateArr) {
            this.dates = this.params.createDateArr;
            this.datesToText();
        }
    }

    private toggle(): void {
        this.$nextTick(() => {
            if (this.isSelectAll) {
                this.params.statusIdArr = [];
                this.isSelectAll = false;
                this.isSelectSome = false;
            }
            else {
                const statusIdArr: number[] = [];
                for (const item of this.items) {
                    statusIdArr.push(item.id);
                }
                this.params.statusIdArr = statusIdArr;

                this.isSelectAll = true;
                this.isSelectSome = false;
            }

            this.change();
        });
    }

    private async reset() {
        await this.$emit("reset");

        if (this.params.createDateArr && this.params.createDateArr.length > 0) {
            this.dates = this.params.createDateArr;
            this.datesToText();
        }
        else {
            this.dates = [];
            this.datesText = "";
        }
    }

    private change(): void {
        const selections = document.querySelector(".search-status-select .v-select__selections") as HTMLElement;
        if (selections) {
            const selection = selections.children;
            for (const dom of selection) {
                if (dom.classList.contains("v-select__selection") || dom.classList.contains("status-selected-count")) {
                    selections.removeChild(dom);
                }
            }

            const div = document.createElement("div");
            div.classList.add("status-selected-count");
            div.innerHTML = "<span>ステータス：" + this.getSelectedItemCount() + "個選択中</span>";
            selections.insertBefore(div, selections.firstElementChild);
        }

        if (this.params.statusIdArr && this.params.statusIdArr.length > 0) {
            if (this.params.statusIdArr.length === this.items.length) {
                this.isSelectAll = true;
                this.isSelectSome = false;
            }
            else {
                this.isSelectAll = false;
                this.isSelectSome = true;
            }
        }
    }

    private getSelectedItemCount(): number {
        if (this.params.statusIdArr) {
            return this.params.statusIdArr.length;
        }

        return 0;
    }
}
