





































import {Component, Vue, Prop} from "vue-property-decorator";
import * as CONFIG from "@/Config";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import {AuthStore, AuthStoreModule} from "@/stores/AuthStore";
import {SheetStore, SheetStoreModule} from "@/stores/SheetStore";
import moment from "moment";
import axios from "axios";

@Component({
    components: {
    }
})
export default class EditThreadDialog extends Vue {
    private isOpend = false;
    private threadId = 0;
    private text = "";

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private get authStore(): AuthStore {
        return AuthStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary タスクを追加するためのダイアログを開きます.
     * @returns 追加する内容
     */
    public show(threadId: number, text: string): void {
        this.isOpend = true;
        this.threadId = threadId;
        this.text = text;
    }

    /**
     * @summary ダイアログを閉じます
     */
    public close(): void {
        this.isOpend = false;
        this.threadId = 0;
        this.text = "";
    }

    /**
     * 日報を新規追加する
     */
    private edit(): void {
        // ここでは処理せず親コンポーネントに任せる
        this.$emit("submit", this.threadId, this.text);
    }
}
