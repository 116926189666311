import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Issue} from "../models/interfaces/Issue";
import WorkLoadCalculator from "../models/WorkLoadCalculator";
import store from "../store";
import {IssueModel} from "@/models/IssueModel";
import {IssueSearchParamModel} from "@/models/IssueSearchParamModel";

/**
 * @summary エラー報告情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "issue", namespaced: true})
export class IssueStore extends VuexModule {
    // #region private filelds
    private _issues: Issue[] = [];
    private _selectedIds: number[] = [];
    // #endregion

    // #region public getters
    public get issues(): Issue[] {
        return this._issues;
    }

    public get selectedIds(): number[] {
        return this._selectedIds;
    }
    // #endregion

    // #region public mutaions
    @Mutation
    public setIssues(issues: Issue[]) {
        this._issues = issues;
    }

    /**
     * @summary 選択状態にします
     * @param {number} issueId エラー報告ID
     */
    @Mutation
    public selectBy(issueId: number): void {
        this._selectedIds = [issueId];
    }

    /**
     * @summary 選択状態のエラー報告を追加します
     * @param {number} issueId エラー報告ID
     */
    @Mutation
    public addSelectBy(issueId: number): void {
        if (this._selectedIds.indexOf(issueId) >= 0) {
            return;
        }

        this._selectedIds.push(issueId);
    }

    /**
     * @summary 未選択状態にします
     * @param {number} issueId エラー報告ID
     */
    @Mutation
    public unselectBy(issueId: number): void {
        this._selectedIds = this._selectedIds.filter(selectedId => selectedId !== issueId);
    }
    // #endregion

    /**
     * @summary エラー報告情報をサーバーから取り込みます.
     */
    @Action
    public async fetchIssues(searchParam: IssueSearchParamModel): Promise<void> {
        const response = await axios.get<Issue[]>("api/issue" + searchParam.getQueryString());
        this.setIssues(response.data);
    }

    /**
     * @summary エラー報告情報を追加します.
     * @param newIssue 追加するエラー報告情報
     */
    @Action
    public async addIssue(newIssue: Issue): Promise<Issue | undefined> {
        try {
            const response = await axios.post("api/issue", newIssue);
            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return undefined;
        }
    }

    /**
     * @summary エラー報告情報を保存します.
     * @param issue 保存するエラー報告情報
     */
    @Action
    public async saveIssue(issue: Issue): Promise<Issue> {
        try {
            const response = await axios.put("api/issue/" + issue.issueId, issue);
            return new IssueModel(response.data);
        }
        catch {
            return new IssueModel({});
        }
    }

    /**
     * @summary エラー報告情報を削除します.
     * @param issue 削除するエラー報告情報
     */
    @Action
    public async deleteIssue(issue: IssueModel): Promise<boolean> {
        try {
            await axios.delete("api/issue/" + issue.issueId);
            return true;
        }
        catch {
            return false;
        }
    }
}

export const IssueStoreModule = getModule(IssueStore);
