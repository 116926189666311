import axios from "axios";
import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {IssueThread} from "../models/interfaces/IssueThread";
import store from "../store";

/**
 * @summary エラー報告情報に関するStoreを提供します.
 */
@Module({dynamic: true, store, name: "issueThread", namespaced: true})
export class IssueThreadStore extends VuexModule {
    // #region private fields
    private _issuesThreads: IssueThread[] = [];
    // #endregion

    // #region public getters
    /**
     * @summary エラー報告リスト
     */
    public get issueThreads(): IssueThread[] {
        return this._issuesThreads;
    }
    // #endregion

    // #region public mutations
    /**
     * @summary エラー報告リストをセットします.
     * @param issues エラー報告リスト
     */
    @Mutation
    public setIssueThreads(issues: IssueThread[]): void {
        this._issuesThreads = issues;
    }
    // #endregion

    // #region actions
    /**
     * @summary エラー報告情報をサーバーから取り込みます.
     */
    @Action
    public async fetchIssueThreads(issueId: number): Promise<void> {
        try {
            this.setIssueThreads([]);
            const response = await axios.get<IssueThread[]>("api/issue_thread/" + issueId);
            this.setIssueThreads(response.data);
        }
        catch (ex) {
            console.error("エラー報告スレッドの取り込みに失敗しました.");
        }
    }

    /**
     * @summary エラー報告情報を追加します.
     * @param newIssue 追加するエラー報告情報
     */
    @Action
    public async addIssueThread(issueThread: IssueThread): Promise<boolean> {
        try {
            const response = await axios.post("api/issue_thread", issueThread);
            this._issuesThreads.push(response.data);
            return true;
        }
        catch (ex) {
            console.error("エラー報告スレッドの追加に失敗しました。", ex);
            return false;
        }
    }

    /**
     * @summary 対応ログスレッド情報を保存します.
     * @param task 保存するタスク情報
     */
    @Action
    public async editTaskThreadText(data: IssueThread): Promise<boolean> {
        try {
            const response = await axios.put("api/issue_thread/" + data.issueThreadId, data);
            return true;
        }
        catch {
            console.error("タスクの保存に失敗しました.");
            return false;
        }
    }

    /**
     * @summary エラー報告スレッド情報を削除します.
     * @param task 削除するタスク情報
     */
    @Action
    public async delete(threadId: number): Promise<boolean> {
        const response = await axios.delete("api/issue_thread/" + threadId);
        if (response.data && response.data.result) {
            return true;
        }
        else {
            return false;
        }
    }
    // #endregion
}

export const IssueStoreModule = getModule(IssueThreadStore);
