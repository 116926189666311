























































































































































































import {Component, Vue} from "vue-property-decorator";
import * as CONFIG from "@/Config";
import {IssueStore, IssueStoreModule} from "../../stores/IssueStore";
import DeleteDialog from "../Atoms/DeleteDialog.vue";
import TableSelectableItem from "../Atoms/TableSelectableItem.vue";
import AddIssueDialog from "../Organisms/AddIssueDialog.vue";
import TableTextFieldDialog from "@/components/Atoms/TableTextFieldDialog.vue";
import TableTextareaDialog from "@/components/Atoms/TableTextareaDialog.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {Issue} from "@/models/interfaces/Issue";
import {IssueModel} from "@/models/IssueModel";
import {MasterInfoStore, MasterInfoStoreModule} from "@/stores/MasterInfoStore";
import {IdAndName} from "../../models/interfaces/IdAndName";
import IssueThreadView from "../Templates/IssueThreadView.vue";
import {SheetStoreModule, SheetStore} from "@/stores/SheetStore";
import {IssueSearchParamModel} from "@/models/IssueSearchParamModel";
import IssueSearchForm from "../Organisms/IssueSearchForm.vue";
import TableDatePickerDialog from "../Atoms/TableDatePickerDialog.vue";
import {VDataTableOptions} from "../../models/interfaces/VDataTableOptions";

/**
 * @summary エラー報告ページを表示するためのビューを提供します.
 */
@Component({
    components: {
        DeleteDialog,
        TableSelectableItem,
        AddIssueDialog,
        TableTextFieldDialog,
        TableTextareaDialog,
        TableDatePickerDialog,
        NameById,
        IssueThreadView,
        IssueSearchForm
    }
})
export default class IssuePage extends Vue {
    private isLoading = false;
    private tableHeight = 0;
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private isShowSearchPanel = false;
    private openedThreadId = 0;
    private searchParams: IssueSearchParamModel = CONFIG.DefaultIssueSearchParam();
    private headers = [
        {text: "", value: "thread", sortable: false, width: 26},
        {text: "No", value: "issueId", width: 80, align: "center"},
        {text: "作成日", value: "creationDate", width: 140},
        {text: "ステータス", value: "statusId", width: 100},
        {text: "システム", value: "systemId", width: 100},
        {text: "担当者名", value: "personId", width: 100},
        {text: "報告内容", value: "requestDetails", sortable: false, width: 420},
        {text: "対応内容", value: "workingDetails", sortable: false, width: 420},
        {text: "", value: "delete", sortable: false, width: 60}
    ];

    /**
     * @summary エラー報告のStore情報
     */
    private get issueStore(): IssueStore {
        return IssueStoreModule;
    }

    /**
     * @summary マスタ情報のStore情報
     */
    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    /**
     * @summary シート情報のStore情報
     */
    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    /**
     * @summary コンポーネント作成時
     */
    private async created(): Promise<void> {
        const json = localStorage.getItem("sortBy_issue");
        if (json) {
            this.sortBy = JSON.parse(json);
        }

        const sortDescJson = localStorage.getItem("sortDesc_issue");
        if (sortDescJson) {
            this.sortDesc = JSON.parse(sortDescJson);
        }

        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 128;
        });
        this.tableHeight = window.innerHeight - 128;

        this.isLoading = true;
        this.loadSearchParam();
        await this.issueStore.fetchIssues(this.searchParams);
        this.isLoading = false;
    }

    private loadSearchParam(): void {
        const json = localStorage.getItem("issue_searchParam");
        if (json) {
            Object.assign(this.searchParams, JSON.parse(json));
        }
        else {
            this.searchParams = CONFIG.DefaultIssueSearchParam();
        }

        this.searchParams.sheetId = this.sheetStore.currentSheetId;
    }

    private saveSearchParam(): void {
        localStorage.setItem("issue_searchParam", JSON.stringify(this.searchParams));
    }

    private onUpdateOptions(options: Partial<VDataTableOptions>): void {
        this.sortBy = [];
        if (options.sortBy) {
            this.sortBy = options.sortBy;
            localStorage.setItem("sortBy_issue", JSON.stringify(options.sortBy));
        }

        this.sortDesc = [];
        if (options.sortDesc) {
            this.sortDesc = options.sortDesc;
            localStorage.setItem("sortDesc_issue", JSON.stringify(options.sortDesc));
        }
    }

    private async submitSearch(): Promise<void> {
        this.isLoading = true;
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.issueStore.fetchIssues(this.searchParams);
        this.saveSearchParam();
        this.isLoading = false;
    }

    private resetSearchDialog(): void {
        this.searchParams = CONFIG.DefaultIssueSearchParam();
    }

    /**
     * @summary エラー報告を保存します
     * @param issue 保存するエラー報告
     */
    private async save(issue: Issue): Promise<void> {
        this.isLoading = true;
        await this.issueStore.saveIssue(issue);
        this.isLoading = false;
    }

    /**
     * エラー報告を削除します.
     * @param issue 削除するエラー報告
     */
    private async deleteIssue(issue: Issue): Promise<void> {
        const deleteDialog = this.$refs.deleteDialog as DeleteDialog;
        if (deleteDialog && !await deleteDialog.showAsync(`No.${issue.issueId}`, "を削除しますか？")) {
            return;
        }

        this.isLoading = true;
        if (await this.issueStore.deleteIssue(issue) && this.sheetStore.currentSheetId !== undefined) {
            this.searchParams.sheetId = this.sheetStore.currentSheetId;
            await this.issueStore.fetchIssues(this.searchParams);
        }
        this.isLoading = false;
    }

    /**
     * @summary エラー報告を追加します.
     */
    private async addIssue(): Promise<void> {
        const dialog = this.$refs.addIssueDialog as AddIssueDialog;
        if (!dialog) {
            return;
        }

        const issue = await dialog.showAsync(new IssueModel({}));
        if (!issue) {
            return;
        }

        this.isLoading = true;
        const newIssue = await this.issueStore.addIssue(issue);
        if (newIssue) {
            this.issueStore.issues.push(newIssue);
        }
        this.isLoading = false;
    }

    private async openDatePickerDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.datePickerDialog as TableDatePickerDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextFieldDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textFieldDialog as TableTextFieldDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openTextareaDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textAreaDialog as TableTextareaDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async openSelectableItem(event: MouseEvent, items?: {[key: number]: IdAndName}, values?: {id: number, name: string}[]): Promise<number | undefined> {
        const dialog = this.$refs.selectableItem as TableSelectableItem;
        if (!dialog) {
            return undefined;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return undefined;
        }

        if (!items) {
            items = [{id: 0, name: ""}];
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(items, rect.left, rect.top, values);
        if (!result) {
            throw new Error("input cancel");
        }

        return result;
    }

    private async showThread(issue: IssueModel): Promise<void> {
        const issueThreadView = this.$refs.issueThreadView as IssueThreadView;
        if (issueThreadView && issue) {
            // 選択状態にする
            this.select(issue.issueId);

            if (this.openedThreadId === issue.issueId) {
                this.openedThreadId = 0;
                issueThreadView.close();
            }
            else {
                this.openedThreadId = issue.issueId;
                await issueThreadView.showAsync(issue);
            }
        }
    }

    private systemColClass(): string {
        return "system-col";
    }

    private select(issueId: number): void {
        if (this.issueStore.selectedIds.indexOf(issueId) >= 0) {
            this.issueStore.unselectBy(issueId);
        }
        else {
            this.issueStore.selectBy(issueId);
        }
    }
}
