




















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class NotifyDialog extends Vue {
    @Prop({default: 400}) readonly width!: number;

    private isShow = false;
    private dialogTitle = "";
    private dialogText = "";
    private closeButtonText = "";
    private resolve?: (value: PromiseLike<void> | void) => void = undefined;

    public showAsync(dialogTitle: string, dialogText: string, closeButtonText: string): Promise<void> {
        this.dialogTitle = dialogTitle;
        this.dialogText = dialogText;
        this.closeButtonText = closeButtonText;
        this.isShow = true;
        return new Promise<void>(resolve => {
            this.resolve = resolve;
        });
    }

    private close() {
        this.isShow = false;
        if (this.resolve) {
            this.resolve();
        }
    }
}
